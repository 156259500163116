import React, { useState, useEffect, Fragment } from "react";
import Formulario from "./components/Formulario";
import ListadoImagenes from "./components/ListadoImagenes";
import Spinner from "./components/Spinner";

function App() {
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [cargando, setCargando] = useState(false);

  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);

  useEffect(() => {
    if (terminoBusqueda === "") return;

    const consultarAPI = async () => {
      setCargando(true);
      const imagenesPorPagina = 30;
      const key = "15269173-61099e5cd7d4caaebf9b20fa6";
      const url = `https://pixabay.com/api/?key=${key}&q=${terminoBusqueda}&per_page=${imagenesPorPagina}&page=${paginaActual}`;

      const consulta = await fetch(url);
      const respuesta = await consulta.json();

      setImagenes(respuesta.hits);

      //calcular el total de paginas

      const calcularTotalPaginas = Math.ceil(
        respuesta.totalHits / imagenesPorPagina
      );

      if (paginaActual > calcularTotalPaginas) {
        setPaginaActual(1);
      }

      setTotalPaginas(calcularTotalPaginas);

      setTimeout(() => {
        setCargando(false);
      }, 1000);

      const jumbotron = document.querySelector(".jumbotron");

      jumbotron.scrollIntoView({ behavior: "smooth" });
    };

    consultarAPI();
  }, [terminoBusqueda, paginaActual]);

  // definir pagina anterior
  const paginaAnterior = () => {
    const nuevaPaginaActual = paginaActual - 1;

    if (nuevaPaginaActual === 0) return;

    setPaginaActual(nuevaPaginaActual);
  };

  // definir pagina siguiente
  const paginaSiguiente = () => {
    const nuevaPaginaActual = paginaActual + 1;

    if (nuevaPaginaActual > totalPaginas) return;

    setPaginaActual(nuevaPaginaActual);
  };

  return (
    <div className="container animated fadeIn">
      <div className="jumbotron">
        <p className="lead text-center">Buscador de Imágenes</p>

        <Formulario setTerminoBusqueda={setTerminoBusqueda} />
      </div>

      {cargando ? (
        <Spinner />
      ) : (
        <div className="row justify-content-center">
          <ListadoImagenes
            imagenes={imagenes}
            terminoBusqueda={terminoBusqueda}
          />

          {totalPaginas === 0 || terminoBusqueda === "" ? null : (
            <Fragment>
              <div className="col-6 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-info mr-1"
                  onClick={paginaAnterior}
                  disabled={paginaActual <= 1 ? true : false}
                >
                  &laquo; Anterior
                </button>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={paginaSiguiente}
                  disabled={paginaActual >= totalPaginas ? true : false}
                >
                  Siguiente &raquo;
                </button>
              </div>

              <div className="my-5 col-12 d-flex justify-content-center">
                <div className="alert alert-secondary">
                  Página {paginaActual} de {totalPaginas}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
